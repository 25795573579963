@import url("https://fonts.googleapis.com/css2?family=Anonymous+Pro:wght@400;700&display=swap");

body {
	margin: 0;
	font-family: "Anonymous Pro", monospace;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	background-color: #121212;
	color: #EEEEEE;
}

#header {
	height: 90vh;
}

h1 {
	font-weight: 700;
	font-size: xxx-large;
}

section h2 {
	font-weight: 700;
}

h3 {
	font-size: large;
}

.chevron {
	position: absolute;
	left: 50%;
	bottom: 0;

	animation-name: animate-chevron;
	animation-duration: 1s;
	animation-iteration-count: infinite;
	animation-direction: alternate;
	transition-timing-function: ease;
}


@keyframes animate-chevron {
	from {
		transform: translateY(-25px);
	}

	to {
		transform: translateY(0px)
	}
}

.card {
	background-color: black;
}
